import {
  ArrowLeftIcon,
  ArrowRightIcon,
  PencilIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import { Formik } from 'formik'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AddSection from '../components/add_section'
import Button from '../components/button'
import ConfirmationDialog from '../components/dialog/confirmation_dialog'
import Input from '../components/input'
import Modal from '../components/modals'
import { Sidebar } from '../components/navigation/sidebar'
import app_api from '../config/api'
import { bgColor } from '../data/dummy_data'
import { classNames } from '../helpers/classname'
import { examSchema } from '../schema'
import { Switch } from '@headlessui/react'
import moment from 'moment'
import Select from 'react-select'
import Table from '../components/tables/table'
import { examTableColumn } from '../components/tables/tableheader'
import { CiFilter } from 'react-icons/ci'
import { toast } from 'react-toastify'
const examStatus = [
  { id: 0, name: 'active' },
  { id: 1, name: 'draft' },
]

const examType = [
  { id: 0, name: 'regular' },
  { id: 1, name: 'workshop' },
]

const initialModalState = {
  type: '',
  state: false,
  index: null,
  edit_id: '',
  data: {
    name: '',
    previewMode: false,
    is_active: true,
    duration: '',
    status: '',
    type: '',
    expireInDays: '',
  },
}

export default function Exam() {
  const [modal, setModal] = useState(initialModalState)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [exams, setExams] = useState([])
  const [confirmationDialog, setConfirmationDialog] = useState(false)
  const [enabled, setEnabled] = useState(false)
  const navigate = useNavigate()
  // const [singleExam, setSingleExam] = useState([])
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(15)
  const [totalPages, setTotalPages] = useState(1)
  const [count, setCount] = useState(0)
  const [searchFilter, setSearchFilter] = useState('')
  const [columns, setColumns] = useState([])

  const [isFilterSidebarOpen, setIsFilterSidebarOpen] = useState(false)
  const [examType, setExamType] = useState([{label : "WORKSHOP", value : "workshop"}, {label : "REGULAR", value : "regular"}])
  const [filterData, setFilterData] = useState({    
    type: '',
    loading: false,
  })

  console.log(examType);

  const handleFilters = () => {
    setFilterData({
      ...filterData,
      loading: true,
    })    
    setExamType(filterData.type)    
  }

  const handleClearFilters = () => {    
    setExamType([])
    setIsFilterSidebarOpen(!isFilterSidebarOpen)
  }

  const goPrev = () => {
    if (page > 0) setPage((prev) => --prev)
  }

  const goNext = () => {
    if (page < totalPages - 1) setPage((prev) => ++prev)
  }

  const onEditOpen = (id, index) => {
    setModal((prev) => ({
      ...prev,
      type: 'edit',
      edit_id: id,
      index: index,
      state: true,
      data:
        exams[index] && exams[index].type
          ? { ...exams[index] }
          : { ...exams[index], type: 'regular' },
    }))
  }

  const onDuplicate = (id, index) => {
    setModal((prev) => ({
      ...prev,
      type: 'duplicate',
      edit_id: id,
      index: index,
      state: true,
      data:
        exams[index] && exams[index].type
          ? { ...exams[index] }
          : { ...exams[index], type: 'regular' },
    }))
  }

  console.log(modal);

  const onDeleteOpen = (id, index) => {
    setModal((prev) => ({ ...prev, id: id, index: index }))
    setConfirmationDialog(true)
  }

  const cleanModalData = () => {
    setModal(initialModalState)
  }

  const createExam = () => {
    app_api
      .post('/exam', { ...modal.data, is_active: true })
      .then((res) => {
        cleanModalData()
        getExamData()
      })
      .catch((err) => {
        setError(err.toString)
      })
  }

  const updateExam = (id) => {
    app_api
      .patch(`/exam/${id}`, modal.data)
      .then((res) => {
        let updatedExamData = [...exams]
        updatedExamData[modal.index] = modal.data
        setExams(updatedExamData)
        cleanModalData()
      })
      .catch((err) => {})
  }

  const onDeleteLocation = () => {
    const { id, index } = modal
    app_api
      .patch(`/exam/archive/${id}`, { archive: 1 })
      .then((res) => {
        let updateExam = [...exams]
        updateExam.splice(index, 1)
        setExams(updateExam)
        setConfirmationDialog(false)
      })
      .catch((err) => {
        setError(err.toString())
      })
  }

  const onModalHandleChange = (name, value) => {
    setModal((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        [name]: value,
      },
    }))
  }

  const renderModal = () => {
    const { type, state, edit_id, data } = modal
    const { name, previewMode, is_active, duration, status, expireInDays } =
      data
    return (
      <Formik
        initialValues={{
          ...data,
          type: data.type || 'regular',
          expireInDays: data.expireInDays || 1,
        }}
        validationSchema={examSchema}
        enableReinitialize
        onSubmit={(values, { setSubmitting }) => {
          const finalValues = {
            // added this variable
            ...values,
            type: values.type || 'regular',
            expireInDays: values.expireInDays || 1,
          }
          if (type === 'add') {
            app_api
              .post('/exam', { ...finalValues, is_active: true })
              .then((res) => {
                cleanModalData()
                getExamData()
                setSubmitting(false)
              })
              .catch((err) => {
                setError(err.toString)
              })
          } else if (type == 'ediit') {
            delete values.questions
            app_api
              .patch(`/exam/${edit_id}`, {
                ...finalValues,
                previewMode: enabled,
              })
              .then((res) => {
                let updatedExamData = [...exams]
                updatedExamData[modal.index] = modal.data
                setEnabled(res.data.previewMode)
                setExams(updatedExamData)
                cleanModalData()
                getExamData()
                setSubmitting(false)
              })
              .catch((err) => {})
          } else{
            console.log(values);
            delete values.questions
            app_api
              .post(`/exam/duplicate/${edit_id}`, {
                ...finalValues,
                previewMode: enabled,
              })
              .then((res) => {
                console.log(res);
                let updatedExamData = [...exams]
                updatedExamData[modal.index] = modal.data
                setEnabled(res.data.previewMode)
                setExams(updatedExamData)
                cleanModalData()
                getExamData()
                setSubmitting(false)
              })
              .catch((err) => {
                console.log(err.response.data.message);
                toast.success('Deleted Successfully')
              })
          }
        }}
      >
        {({
          handleBlur,
          Formik,
          handleChange,
          handleSubmit,
          setValues,
          values,
          touched,
          isValid,
          isSubmitting,
          errors,
        }) => (
          <Modal
            title={type === 'add' ? 'Add Exam' ? type === 'edit'  : 'Edit Exam' : 'Duplicate Exam'}
            open={state}
            setOpen={() => {
              setModal((prev) => ({ ...prev, state: false }))
              cleanModalData()
            }}
          >
            <form onSubmit={handleSubmit}>
              <div className="text-left mt-4">
                <Input
                  name="name"
                  label="Name"
                  placeholder="Please enter exam name"
                  value={values.name}
                  autoComplete="off"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  // onChange={(event) =>
                  //   onModalHandleChange(event.target.name, event.target.value)
                  // }
                />
                {touched.name && (
                  <p className="text-red-700 error_msg">{errors.name}</p>
                )}
                <div className="mt-4"></div>
                <Input
                  type="number"
                  name="duration"
                  label="Duration"
                  placeholder="Duration in minutes"
                  value={values.duration}
                  autoComplete="off"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  // onChange={(event) =>
                  //   onModalHandleChange(event.target.name, event.target.value)
                  // }
                />
                {touched.duration && (
                  <p className="text-red-700 error_msg">{errors.duration}</p>
                )}
                {/* <div className="mt-4"></div>
                <label
                  htmlFor="status"
                  className="block text-sm font-medium text-gray-700"
                >
                  Status
                </label>
                <Select
                  id="status"
                  name="status"
                  className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  value={
                    values.status
                      ? examStatus.map((l) => ({
                          value: examStatus.find(
                            (e) => e.name == values.status.toLowerCase()
                          )?.id,
                          label: examStatus
                            .find((e) => e.name == values.status.toLowerCase())
                            ?.name.toUpperCase(),
                        }))
                      : null
                  }
                  placeholder="Please select the status of the exam"
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={(option) => {
                    setValues({
                      ...values,
                      status: option.label.toLowerCase(),
                    })
                  }}
                  options={examStatus?.map((e) => ({
                    ...e,
                    label: e.name.toUpperCase(),
                    value: e.id,
                  }))}
                ></Select>
                {touched.status && (
                  <p className="text-red-700 error_msg">{errors.status}</p>
                )} */}
                <div className="mt-4">
                  <label
                    htmlFor="status"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Status
                  </label>
                  <Select
                    id="status"
                    name="status"
                    className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    value={
                      values.status
                        ? examStatus.map((l) => ({
                            value: examStatus.find(
                              (e) => e.name == values.status.toLowerCase()
                            )?.id,
                            label: examStatus
                              .find(
                                (e) => e.name == values.status.toLowerCase()
                              )
                              ?.name.toUpperCase(),
                          }))
                        : null
                    }
                    placeholder="Please select the status of the exam"
                    autoComplete="off"
                    onBlur={handleBlur}
                    onChange={(option) => {
                      setValues({
                        ...values,
                        status: option.label.toLowerCase(),
                      })
                    }}
                    options={examStatus?.map((e) => ({
                      ...e,
                      label: e.name.toUpperCase(),
                      value: e.id,
                    }))}
                  ></Select>
                  {touched.status && (
                    <p className="text-red-700 error_msg">{errors.status}</p>
                  )}
                </div>
                {/* <div className="mt-4"></div>
                <label
                  htmlFor="expireInDays"
                  className="block text-sm font-medium text-gray-700"
                >
                  Validity:
                </label>
                <Input
                  name="expireInDays"
                  type="number"
                  placeholder="Number of days"
                  value={values.expireInDays}
                  className="mb-2"
                  // onChange={(event) =>
                  //   onModalHandleChange(event.target.name, event.target.value)
                  // }
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.expireInDays && (
                  <p className="text-red-700 error_msg">
                    {errors.expireInDays}
                  </p>
                )} */}
                <div className="mt-4">
                  <label
                    htmlFor="type"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Type
                  </label>
                  <Select
                    id="type"
                    name="type"
                    className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    value={
                      values.type
                        ? {
                            value: examType.find(
                              (e) => e.value === values.type.toLowerCase()
                            )?.value,
                            label: examType
                              .find((e) => e.value === values.type.toLowerCase())
                              ?.value.toUpperCase(),
                          }
                        : null
                    }
                    placeholder="Please select the type of the exam"
                    autoComplete="off"
                    onBlur={handleBlur}
                    onChange={(selectedOption) => {
                      const selectedValue = selectedOption
                        ? selectedOption.label.toLowerCase()
                        : 'regular'
                      setValues({
                        ...values,
                        type: selectedValue,
                      })
                    }}
                    options={examType?.map((e) => ({
                      value: e.value,
                      label: e.label.toUpperCase(),
                    }))}
                  />
                  {touched.type && (
                    <p className="text-red-700 error_msg">{errors.type}</p>
                  )}
                </div>
                <div className="mt-4 hidden">
                  <label
                    htmlFor="expireInDays"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Validity:
                  </label>
                  <Input
                    name="expireInDays"
                    type="number"
                    placeholder="Number of days"
                    value={values.expireInDays || 1}
                    className="mb-2"
                    autoComplete="off"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  {touched.expireInDays && (
                    <p className="text-red-700 error_msg">
                      {errors.expireInDays}
                    </p>
                  )}
                </div>
                <div className="mt-4"></div>
                <Switch.Group
                  as="div"
                  className="flex items-center justify-between"
                >
                  <span className="flex flex-grow flex-col">
                    <Switch.Label
                      as="span"
                      className="text-sm font-medium text-gray-900"
                      passive
                    >
                      Exam Preview
                    </Switch.Label>
                  </span>
                  <Switch
                    checked={enabled}
                    onChange={setEnabled}
                    className={classNames(
                      enabled ? 'bg-indigo-600' : 'bg-gray-200',
                      'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 toggleButton'
                    )}
                  >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        enabled ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                      )}
                    />
                  </Switch>
                </Switch.Group>

                <div className="mt-4 sm:mt-6">
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    // onClick={() => {
                    //   type === 'add' ? createExam() : updateExam(edit_id)
                    // }}
                  >
                    {isSubmitting
                      ? type === 'add'
                        ? 'Adding...'
                        : type === 'update'
                        ? 'Saving...'
                        : type === 'duplicate'
                        ? 'Duplicating...'
                        : 'Submitting...'
                      : type === 'add'
                      ? 'Add Exam'
                      : type === 'update'
                      ? 'Update Exam'
                      : type === 'duplicate'
                      ? 'Duplicate Exam'
                      : 'Submit'}
                  </Button>
                </div>
              </div>
            </form>
          </Modal>
        )}
      </Formik>
    )
  }

  const getExamData = () => {
    console.log(examType);
    let url = `/exam?page=${page}&size=${limit}&searchFilter=${searchFilter}`
    if (examType.length > 0) {
      url += `&type=${examType[0].value}`
    }
    app_api
      .get(url)
      .then((res) => res.data)
      .then((res) => {
        console.log(res)
        setLoading(false)
        setError(null)
        setExams(res.data)
        if (res.count <= limit) {
          setPage(0)
        }
        setTotalPages(Math.ceil(res.count / limit))
        setCount(res.count)
      })
      .catch((err) => {
        setLoading(false)
        setError(err?.response?.data?.message || 'error getting data')
      })
  }

  useEffect(() => {
    getExamData()
  }, [page, limit, searchFilter, examType])

  const onNavigation = (id) => {
    navigate(`/exam/${id}`)
  }

  return (
    <Sidebar>
      <ConfirmationDialog
        setOpen={setConfirmationDialog}
        open={confirmationDialog}
        onDelete={onDeleteLocation}
      />
      {renderModal()}
      <div className="px-4 pt-2 sm:px-6 lg:px-8 ContainerUI sticky min-h-screen">
        {JSON.parse(localStorage.getItem('moldKey')).role !==
        'exam_view_admin' ? (
          <>
            <AddSection
              title="Exams"
              buttonText="Add"
              onClick={() =>
                setModal((prev) => ({ ...prev, type: 'add', state: true }))
              }
              description="You can add new exams"
            />
          </>
        ) : (
          <></>
        )}

        {/* <div className="relative mx-2">
          <div className="w-full flex md:mt-10 mt-16 absolute justify-between gap-2">
            <div></div>
            <div className="flex justify-between gap-4 ">
              <div
                onClick={() => setIsFilterSidebarOpen(!isFilterSidebarOpen)}
                className="rounded-full py-2 px-2 cursor-pointer border shadow-sm bg-indigo-800 hover:bg-indigo-700 text-white"
              >
                {filterData.loading ? (
                  <div className="flex items-center justify-center">
                    <div
                      className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full"
                      role="status"
                    ></div>
                  </div>
                ) : (
                  <CiFilter className="w-6 h-6" />
                )}
              </div>
            </div>
          </div>
        </div> */}

        {isFilterSidebarOpen && (
          <div className="h-full">
            <div
              className={`absolute bg-white  border rounded-lg shadow-lg w-full  md:w-1/2 lg:w-1/4 md:top-24 md:right-0 top-28 z-50`}
            >
              <div className="flex justify-between p-2">
                <span className=" text-sm text-gray-700 ">Filters</span>
                <span
                  className="text-sm text-indigo-700 cursor-pointer"
                  onClick={() => {
                    setFilterData({                    
                      type: [],
                      loading: false,
                    })
                    handleClearFilters()
                  }}
                >
                  {' '}
                  Reset All{' '}
                </span>
              </div>
              <hr className="" />             
              <div className="w-full p-2">
                <div className="flex justify-between mb-2">
                  <span className=" text-sm text-gray-700 ">Exam Type</span>
                  <span
                    className="text-sm text-indigo-700 cursor-pointer"
                    onClick={() => {
                      setFilterData({
                        ...filterData,
                        type: [],
                      })
                    }}
                  >
                    {' '}
                    Reset{' '}
                  </span>
                </div>
                <Select
                  className="text-left block w-full appearance-none rounded-md placeholder-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  name="type"
                  id="type"
                  isSearchable
                  loadingMessage="Getting type..."
                  placeholder="type"
                  options={[{label : "WORKSHOP", value : "workshop"}, {label : "REGULAR", value : "regular"}]}
                  value={filterData.type}
                  onChange={(option) => {
                    setFilterData({
                      ...filterData,
                      type: option,
                    })
                  }}
                />
              </div>                           
              <hr className="" />
              <div className="flex justify-between gap-2 p-2">
                <div className="">
                  <Button
                    type="button"
                    className={'bg-red-500 hover:bg-red-700'}
                    onClick={() => {
                      setFilterData({                        
                        type: [],
                        loading: false,
                      })
                      handleClearFilters()
                    }}
                  >
                    Reset All
                  </Button>
                </div>
                <div className="">
                  <Button
                    type="button"
                    onClick={() => {
                      handleFilters()
                    }}
                  >
                    Apply Now
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}

        <Table
          progPage={page}
          totalCount={count}
          columns={examTableColumn({
            onEditOpen,
            setEnabled,
            onNavigation,
            onDeleteOpen,
            columns,
            onDuplicate
          })}
          data={exams}
          onEditClose={cleanModalData}
          setSearchFilter={setSearchFilter}
        />
        <div
          className="flex flex-col lg:flex-row items-center justify-between border-t border-gray-200 bg-white px-4 py-4 sm:px-1"
          aria-label="Pagination"
        >
          {/* Left section */}
          <div className="flex justify-center lg:justify-normal items-center w-full lg:w-[50%]">
            <p className="text-sm text-center font-bold text-gray-700">
              Showing{' '}
              <select
                onChange={(e) => {
                  const newLimit = parseInt(e.target.value)
                  setLimit(newLimit)
                  setPage(0)
                }}
                value={limit}
                className="border rounded-md px-2 py-1 focus:outline-none focus:ring-indigo-100 focus:border-indigo-700"
              >
                <option value={count}>{count}</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
              </select>{' '}
              of <span className="font-medium">{count}</span> results
            </p>
          </div>

          {/* Right section */}
          <div className="flex items-center justify-center w-full lg:w-[50%] mt-3">
            <span
              onClick={goPrev}
              className="relative rounded-md text-sm font-semibold text-gray-900 border p-2 hover:bg-indigo-800 hover:text-white duration-500 cursor-pointer"
            >
              <ArrowLeftIcon className="w-5 h-5" />
            </span>

            <div className="flex items-center mx-3">
              <span className="hidden md:block">Page</span>
              <input
                className="rounded-md w-14 border border-gray-300 px-3 py-2 focus:border-indigo-700 focus:outline-none focus:ring-indigo-100 sm:text-sm mx-2"
                type="number"
                value={page + 1}
                onChange={(e) => setPage(e.target.value - 1)}
                max={totalPages}
                min="1"
              />
              <span>/ {Math.ceil(totalPages)}</span>
            </div>

            <span
              onClick={goNext}
              className="relative rounded-md px-3 py-2 text-sm font-semibold text-gray-900 border hover:bg-indigo-800 hover:text-white duration-500 cursor-pointer"
            >
              <ArrowRightIcon className="w-5 h-5" />
            </span>
          </div>
        </div>
      </div>
    </Sidebar>
  )
}
